(function (scope, undef) {
    "use strict";
    var w = scope.window, $ = scope.jQuery, af = scope.af, c = af.common, console = scope.console, shared = {
        parseJSON: $.parseJSON,
        isodate: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,7}))?Z?$/
    };
    var oldTechSite = '/legacy';
    var urlRewrites = [
        

            '/api/status/processdocument2/',
            'api/statusreports/word/getTextConten',
           

    ];
    function fixFilter(pObj) {
        if (!pObj) return null;

        if (pObj.length) {
            for (var i = 0; i < pObj.length; i++) {
                if (pObj[i] && pObj[i].column && pObj[i].column.toLowerCase().indexOf("isnull") > -1 && pObj[i].operator.indexOf("inlist") > -1) {
                    pObj[i].column = `${extractFirstWordFromBrackets(pObj[i].column)}`;
                }
                fixFilter(pObj[i]);
            }
        }
        if (pObj.items) {
            fixFilter(pObj.items);
        }

        return pObj;
    }
    function extractFirstWordFromBrackets(input) {
        // Regular expression to match the first word inside square brackets
        const match = input.match(/\[(\w+)\]/);
        
        // Return the first captured group or null if no match is found
        return match ? match[1] : null;
    }

    function ajax(pData){
        if(pData.url.indexOf("api/contract/review") > -1)  return $.ajax(pData);
        if(pData.url == "/api/filtering/parse-filter"){
            pData.url = "/api/filtering/parseFilterStringToJson"
        }

        if(pData.data && typeof pData.data === "object" && pData.data.constructor !== FormData){
            if(!pData.data.filterString) return null;
            pData.data = JSON.stringify(pData.data);
        }
        // if(!pData.dataType){
        //     //pData.dataType = "json";            
        // }
        if(!pData.contentType){
            pData.contentType= "application/json; charset=utf-8";
        }

        if( pData.url == "/api/filtering/parseFilterStringToJson"){
            var vOldS = pData.success;
            pData.success = (pParams)=>{
                pParams = fixFilter(pParams);
                vOldS(pParams);
            } 
            return $.ajax(pData);
        }
        
        pData.url = getRewritedUrl(pData.url);
        return $.ajax(pData);
    }
    c.expose("af", ajax);

    function dateReviver(key, value) {
        // New method of parsing dates - much faster
        if (typeof value === "string") {
            if (!value.indexOf("/Date(")) { // indexOf should return 0 if date, and ! makes 0 true
                value = (new XDate(Number(value.substring(6, value.length - 2)))).toDate(); // + timezoneOffset); // not used anymore
            } else if (shared.isodate.test(value)) {
                value = (new XDate(value)).toDate();
            }
        }
        return value;
    }
    function parseDateRows(rows) {
        for (var i in rows) {
            rows[i] = dateReviver(rows[i]);
        }
    }
    function parseData(data) {
        var i, d;
        // Return if data is falsy
        if (!data) { return; }
        // If data is an array of responses, loop them, and run parseData on the successful ones
        if (typeof data === 'object' && data instanceof Array && data.length) {
            for (i = 0; i < data.length; i++) {
                if (data[i].status === 'success' && data[i].data) {
                    parseData(data[i].data);
                }
            }
        } else { // Else, just parse the data
            // set d to data.data if it exists, else just use data
            d = data.data || data;
            // Return if d is falsy
            if (!d) { return; }
            // If d is an array, loop it and parse it
            if (typeof d === 'object' && d instanceof Array) {
                for (i = 0; i < d.length; i++) {
                    if (d[i] && typeof d[i] === 'object') {
                        parseDateRows(d[i]);
                    }
                }
            } else if (typeof d === 'object') { // If d is an object, parse it
                parseDateRows(d);
            }
        }
    }
    function afParseJSON(data) {
        // If native JSON, pass revivor, else loop parsed json
        if (typeof JSON === 'object' && typeof JSON.parse === 'function') {
            if (typeof data !== "string" || !data) { return null; }
            data = JSON.parse(data, dateReviver);
        } else {
            data = shared.parseJSON(data);
            parseData(data);
        }
        return data;
    }

    // Override default jQuery json parser
    $.ajaxSettings.converters["text json"] = $.parseJSON = afParseJSON;

    function waitUntilDatabaseWritable(pCallback) {
        $.ajax({
            url: "/api/maintenance/wait",
            timeout: false,
            success: function() {
                pCallback(null);
            },
            error: function(xhr) {
                pCallback(xhr.responseText);
            }
        });
    }

    function handleReadonly(pRetryCallback) {
        if (typeof pRetryCallback === "function") {
            if (c.typeOf(af.controls) === "object" && 
                typeof af.controls.confirm === "function" &&
                typeof af.controls.working === "function") {
                af.controls.confirm({
                    title: "Database is read-only",
                    message: "Database is currently in read-only mode.\n" +
                                "Do you wish to wait until it becomes writable again and retry the request?",
                    buttons: ["Wait and retry", "Cancel"], highlight: 0, enter: 0, escape: 1,
                    callback: function(index) {
                        if (index === 0) {
                            var vWorking = af.controls.working("Waiting for database to become writable...");
                            waitUntilDatabaseWritable(function(err) {
                                vWorking();
                                if (err === null) {
                                    pRetryCallback();
                                } else {
                                    scope.alert(err);
                                }
                            });
                        }
                    }
                });
            } else {
                scope.alert("Database is currently in read-only mode. Please wait a few minutes and try again.");
            }
        }
    }

    function getResponseError(xhr, pRetryCallback) {
        /**
         * Handles an xmlhttprequest response. Possible outcomes:
         * 1: Session has expired - login dialog is shown and pRetryCallback is called after relog.
         *    This function returns false right after dialog is created and shown.
         * 2: Database is readonly - a dialog is shown and pRetryCallback is called when database is no longer readonly.
         * 3: No error has occured - this function returns true.
         * 4: An error has occured - this function returns the error string.
         * @param {object} xhr The XMLHttpRequest object whose response to handle
         * @param {function} pRetryCallback Function that will re-send the call whose response we are handling
         * @return {true|false|string} Returns true if ok, false if session expired, else error string.
         */
        
        var vError, vStatus, vStatusText, vContentType, vJSON;
        try { vError = xhr.responseText; vStatus = xhr.status; vStatusText = xhr.statusText; }
        catch (ex) { /* Assume IE9 and request aborted */ }

        if(xhr.statusText === "timeout"){
            vError = "The execution timed out";
        }

        if (!vError && !vStatus) {
            vError = "Request was aborted"; vStatus = 499; vStatusText = "Client Closed Request";
            if (console && typeof console.log === "function") {
                console.log(vStatus + " " + vStatusText + ": " + vError);
            }
            return false; // By returning false here we avoid showing alerts when request aborted when navigating away from page
        }
        if (vStatus === 401 && (vError === "" || vError === "Session expired" || af.common.isCanToJson(vError) && JSON.parse(vError).error === "You need to be authenticated to load data")) {
            // af.userSession.expired(pRetryCallback);
            handleLogin(pRetryCallback);
            return false;
        } else if (vStatus === 401 && vError === "RequireTwoFactor") {
            af.userSession.expired(pRetryCallback, true);
        } else if (vStatus === 403 && vError === "Database readonly") {
            handleReadonly(pRetryCallback);
            return false;
        } else {
            vContentType = xhr.getResponseHeader("Content-Type");
            if (vContentType && vContentType.indexOf("application/json") === 0) {
                try {
                    vJSON = JSON.parse(vError)
                } catch (ex) {
                    return ex.message;
                }
                if (vJSON && c.objHasKey(vJSON, "error")) {
                    if (console && typeof console.log === "function" && c.objHasKey(vJSON, "stack")) {
                        if (typeof console.groupCollapsed === "function" && typeof console.groupEnd === "function") {
                            console.groupCollapsed(vJSON.error);
                            console.log(vJSON.stack);
                            console.groupEnd();
                        } else {
                            console.log(vJSON.stack);
                        }
                    }
                    return vJSON.error;
                }
            } else if (vStatus !== 200) {
                if (console && typeof console.log === "function") {
                    console.log(vStatus + " " + vStatusText + ": " + vError);
                }
                if (vContentType && vContentType.indexOf("text/html") || vError[0] == "<") {
                    return "Sorry, but there seems to be an error.\nThe server responded with status code: " + vStatus;
                } else {
                    return vError || "Unspecified error";
                }
            }
            return true;
        }
    }


    async function handleLogin(pRetryCallback){
        if (document.getElementById('o365-login-iframe')) { return; }

        const loginIframe = document.createElement('iframe');
        loginIframe.id = 'o365-login-iframe';
        loginIframe.style.backgroundColor = 'transparent';
        loginIframe.style.top = 0;
        loginIframe.style.left = 0;
        loginIframe.style.zIndex = 10000;
        loginIframe.classList.add('w-100', 'h-100', 'position-fixed', 'border-0');
        loginIframe.src = '/login-iframe'

        window.addEventListener('authenticated', () => {
            document.getElementById('o365-login-iframe')?.remove();
            document.body.classList.remove('overflow-hidden');
            if(pRetryCallback && typeof pRetryCallback === 'function'){
                pRetryCallback();
            }
        });

        document.body.append(loginIframe);
        document.body.classList.add('overflow-hidden');
    }

    function DataHandler(pOptions) {
        var that = this;
        function getNotImplementedError(pFuncName) {
            return "The " + pFuncName + " function is not implemented for this DataHandler";
        }
        function getInvalidArgumentsLengthError(pFuncName) {
            return "Invalid number of arguments on the " + pFuncName + " function";
        }
        function implementIfValid(pFuncName) {
            if (pOptions && typeof pOptions[pFuncName] === "function") {
                if (pOptions[pFuncName].length >= 2) {
                    that[pFuncName] = pOptions[pFuncName];
                } else {
                    throw new Error(getInvalidArgumentsLengthError(pFuncName));
                }
            } else {
                that[pFuncName] = function (pParams, pCallback) {
                    var vError = getNotImplementedError(pFuncName);
                    if (typeof pCallback === "function") {
                        pCallback(vError);
                    } else {
                        throw new Error(vError);
                    }
                };
            }
        }


        implementIfValid("create"); // (pRecord, pCallback) - calls pCallback(null, pSavedRecord)
        implementIfValid("retrieve"); // (pParams, pCallback) - calls pCallback(null, pRecords)
        implementIfValid("update"); // (pRecord, pCallback) - calls pCallback(null, pUpdatedRecord)
        implementIfValid("destroy"); // (pRecord, pCallback) - calls pCallback(null, null)
        implementIfValid("rowcount");
        implementIfValid("distinctData");
    }
    function DataProviderHandler(pOptions) {
        var that = this, priv = {
            defaults: {
                articleId: w.af.article && w.af.article.id,
                dataSourceId: null,
                timeout: 0,
                fields:null,
                groupBy:false
            },
            options: {},
            eventHandler: new c.EventHandler(that, {
                onProgress: { abortable: true }, // Fires when download or upload is progressing
                onSuccess: { abortable: false }, // Fires when a request succeds
                onError: { abortable: false }, // Fires when a request fails
                onComplete: { abortable: false} // Fires after a request completes
            }),
            requestTypes: ["create", "retrieve", "update", "destroy","rowcount", "distinct"],
            previousRetrieveRequest: null
        };

        function fireCallback(pCallback, pError, pData) {
            if (typeof pCallback === "function") {
                pCallback.call(that, pError, pData);
            }
        }
        function fireEvent(pEvent, pArgs, pModifiableArgs) {
            return priv.eventHandler.fire(pEvent, pArgs, pModifiableArgs);
        }
        function attachEvent(pEvent, pFunc) {
            return priv.eventHandler.attach(pEvent, pFunc);
        }
        function detachEvent(pEvent, pFunc) {
            return priv.eventHandler.detach(pEvent, pFunc);
        }

        function reportProgress(evt) {
            /*jshint validthis: true */
            var vEventResult = fireEvent("onProgress", evt.lengthComputable ? evt.loaded / evt.total : null);
            if (vEventResult === false) { this.abort(); }
        }

        function request(pType, pData, pCallback, pSynchronous) {

            alert('switch to new api')
            var vRequest, vArgs = arguments;
            if (priv.requestTypes.indexOf(pType) !== -1) {
                if (pType === "retrieve" && priv.previousRetrieveRequest !== null) {
                    priv.previousRetrieveRequest.abort();
                    priv.previousRetrieveRequest = null;
                }
                if (pData !== null && priv.options.dataSourceId) {
                    vRequest = scope.$.ajax({
                        type: "POST",
                        async: !pSynchronous,
                        url: "/" + pType + "/" + priv.options.articleId + "/" + priv.options.dataSourceId + (priv.options.fields ? "/" + (priv.options.fields.length>1?priv.options.fields.toString().replace(/,/g,"-"):priv.options.fields) + "/" + priv.options.groupBy : ""),
                        timeout: priv.options.timeout,
                        data: JSON.stringify(pData),
                        contentType: "application/json; charset=utf-8",
                        dataType: "json",
                        success: function (response, status, xhr) {
                            if (c.objHasKey(response, "success")) {
                                fireEvent("onSuccess", response);
                                fireCallback(pCallback, null, response.success);
                            } else if (c.objHasKey(response, "error")) {
                                if (console && typeof console.log === "function" && c.objHasKey(response, "stack")) {
                                    if (typeof console.groupCollapsed === "function" && typeof console.groupEnd === "function") {
                                        console.groupCollapsed(priv.options.dataSourceId + ":", response.error);
                                        console.log(response.stack);
                                        console.groupEnd();
                                    } else {
                                        console.log([priv.options.dataSourceId + ": " + response.error, response.stack].join("\n"));
                                    }
                                }
                                fireEvent("onError", response.error);
                                fireCallback(pCallback, response.error, null);
                            } else {
                                if (console && typeof console.log === "function") {
                                    console.log(priv.options.dataSourceId + ": " + xhr.status + " " + xhr.statusText + ": Unexpected response from server:\n" + xhr.responseText);
                                }
                                fireEvent("onError", "Unspecified error");
                                fireCallback(pCallback, "Unspecified error", null);
                            }
                        },
                        error: function (xhr) {
                            var vError = getResponseError.call(this, xhr, function () { request.apply(that, vArgs); });
                            if (typeof vError === "string") {
                                fireEvent("onError", vError);
                                fireCallback(pCallback, vError, null);
                            }
                        },
                        complete: function () {
                            if (priv.previousRetrieveRequest === vRequest) {
                                priv.previousRetrieveRequest = null;
                            }
                            fireEvent("onComplete");
                        },
                        xhr: function() {
                            try {
                                var xhr = new scope.XMLHttpRequest();
                                if (xhr.upload && typeof xhr.upload.addEventListener === "function") {
                                    xhr.upload.addEventListener("progress", reportProgress, false);
                                }
                                if (typeof xhr.addEventListener === "function") {
                                    xhr.addEventListener("progress", reportProgress, false);
                                }
                                return xhr;
                            } catch(ex) {}
                        }
                    });
                    if (pType === "retrieve") {
                        priv.previousRetrieveRequest = vRequest;
                    }
                } else {
                    return false;
                }
            } else {
                throw new TypeError("Invalid request type supplied to the request function");
            }
        }

        that.attachEvent = attachEvent;
        that.detachEvent = detachEvent;
        that.request = request;

        // Merge options
        c.mergeOptions(priv.options, priv.defaults, pOptions || {}, 1);
    }
    DataProviderHandler.prototype = new DataHandler({
        create: function (pData, pCallback, pSynchronous) {
            return this.request("create", pData, pCallback, pSynchronous);
        },
        retrieve: function (pData, pCallback, pSynchronous) {
            return this.request("retrieve", pData, pCallback, pSynchronous);
        },
        update: function (pData, pCallback, pSynchronous) {
            return this.request("update", pData, pCallback, pSynchronous);
        },
        destroy: function (pData, pCallback, pSynchronous) {
            return this.request("destroy", pData, pCallback, pSynchronous);
        },
        rowcount: function (pData, pCallback, pSynchronous) {
            return this.request("rowcount", pData, pCallback, pSynchronous);
        },
        distinctData: function (pData, pCallback) {
            return this.request("retrieve", pData, pCallback, false);
        }
    });

    function MemoryStorage() {
        var that = this, vData = [], vNextUID = Date.now();

        function create(pRecord) {
            if (pRecord !== null) {
                if (typeof pRecord !== "object") { throw new TypeError("Data passed to the create function was not an object"); }
                pRecord.__uid = vNextUID++;
            }
            return vData.push(pRecord) - 1; // Returns the index of the record
        }
        function retrieve(pIndex) {
            if (pIndex === undef || pIndex === null) { return vData; }
            if (typeof pIndex !== "number" || isNaN(pIndex)) { throw new TypeError("Index passed to the retrieve function was not a number"); }
            if (pIndex < 0 || pIndex >= vData.length) { throw new Error("No record found at the supplied index: " + pIndex); }
            return vData[pIndex] || null;
        }
        function update(pIndex, pData) {
            var vRow, vUID;
            if (typeof pIndex !== "number" || isNaN(pIndex)) { throw new TypeError("Index passed to the update function was not a number"); }
            if (pIndex < 0 || pIndex >= vData.length || vData[pIndex] === undef) { throw new Error("No record found at the supplied index: " + pIndex); }
            if (typeof pData !== "object") { throw new TypeError("Data passed to the update function was not an object"); }
            vRow = vData[pIndex];
            if (vRow !== null) {
                vUID = vRow.__uid;
            }
            if (pData instanceof Array) {
                vData[pIndex] = pData;
            } else {
                c.extend(vRow, pData);
            }
            if (vRow !== null) {
                vRow.__uid = vUID;
            } else {
                vData[pIndex].__uid = vNextUID++;
            }
            return true;
        }

        function updateAsyncFields(pIndex,pData){
            if (pIndex < 0 || pIndex >= vData.length || vData[pIndex] === undef) { throw new Error("No record found at the supplied index: " + pIndex); };
            pData.forEach(function(val,ind){
                vData[pIndex][val.index] = val.value;
            })

        }
        function destroy(pIndex) {
            if (pIndex === undef || pIndex === null) { vData = []; return true; } //vData.splice(0, vData.length);
            if (typeof pIndex !== "number" || isNaN(pIndex)) { throw new TypeError("Index passed to the destroy function was not a number"); }
            if (pIndex < 0 || pIndex >= vData.length || vData[pIndex] === undef) { throw new Error("No record found at the supplied index: " + pIndex); }
            vData.splice(pIndex, 1);
            return true;
        }

        function merge(pColIndex, pData){
            var vIndex =  vData.findIndex(function(pData2, pIndex){
                if(pData[pColIndex] === pData2?.[pColIndex]){
                    return true
                }
            });
            vData[vIndex] = pData;
        }

        function destroyByField(pObj, pFieldIndex) {
            if (pObj === undef || pObj === null) { vData = []; return true; } //vData.splice(0, vData.length);
            
            var vIndex = vData.findIndex(function(pElement){
                if(pObj[pFieldIndex] === pElement[pFieldIndex]){
                    return true;
                }
            });
            if(vIndex > -1){
                vData.splice(vIndex, 1);
            }
            return true;
        }

        function length() {
            return vData.length;
        }

        that.create = create;
        that.retrieve = retrieve;
        that.update = update;
        that.destroy = destroy;
        that.merge = merge;
        that.length = length;
        that.destroyByField = destroyByField
        that.updateAsyncFields = updateAsyncFields
    }

    
    
    function Api(pOptions) {
        var priv = {
            options: new c.Options({
                defaults: {
                    abortPrevious: false,
                    synchronous: false,
                    timeout: 0,
                    parameters: null
                },
                required: ["url"],
                passed: (typeof pOptions === "string" ? { url: pOptions } : pOptions)
            }),
            hasPlaceholders: false,
            previousRequest: null
        };

        function fireCallback(pCallback, pError, pData) {
            if (typeof pCallback === "function") {
                pCallback.call(null, pError, pData);
            }
        }

        if (!/[\w-]+(\/([\w-]+|<%=\w+%>))*/.test(priv.options.url)) {
            throw new Error("Invalid url give to the Api constructor");
        }
        priv.hasPlaceholders = /<%=\w+%>/.test(priv.options.url);

        return function request(pData, pCallback) {

            var requestContext = this, vArgs = arguments, vUrl = priv.options.url, vData, vParts;
            if (typeof pData === "function") { pCallback = pData; pData = null; }
            if (priv.options.abortPrevious && priv.previousRequest !== null) {
                priv.previousRequest.abort();
                priv.previousRequest = null;
            }
            if(pData && pData.operation === "distinct") pData.operation = "retrieve";
       
            if (priv.hasPlaceholders) {
                vData = {};
                vParts = vUrl.split("/");
                if (c.typeOf(priv.options.parameters) === "object") {
                    c.forEach(priv.options.parameters, function (key, val) {
                        var vPlaceholder = "<%=" + key + "%>",
                            vIndex = vParts.indexOf(vPlaceholder),
                            vValue = (typeof val === "function" ? val(vData, key) : val);
                        if (~vIndex) {
                            vParts[vIndex] = vValue;
                        } else {
                            vData[key] = vValue;
                        }
                    });
                }
                c.forEach(pData, function (key, value) {
                    var vPlaceholder = "<%=" + key + "%>",
                        vIndex = vParts.indexOf(vPlaceholder);
                    if (~vIndex) {
                        vParts[vIndex] = String(value);
                    } else {
                        vData[key] = value;
                    }
                });
                c.forEach(vParts, function (i, part) {
                    if (/<%=\w+%>/.test(part)) {
                        throw new Error("Unspecified api parameter: " + part.match(/<%=(\w+)%>/)[1]);
                    }
                });
                vUrl = vParts.join("/");
            } else {
                vData = pData;
            }

           

           // if(vUrl.endsWith('/load-filters')) return apiOverrides(vUrl,vData,pCallback);

            if (vUrl.toLowerCase().endsWith('api/filter/devextosql')) {
                vUrl = '/api/filtering/devExpressToSql';
            }

            if (vUrl.toLowerCase().includes('api/wopi')) {
                vUrl = vUrl.replace('api/wopi', 'wopi');
            }
            if(vUrl.indexOf("api/apps/data")>-1){
                vUrl = vUrl.replace('api/apps/data','api/data');
            }
            if(vUrl.indexOf("api/get-upload-progress")>-1){
                vUrl = vUrl.replace('api/get-upload-progress','api/file/uploadprogress');
            }

            if(vData && vUrl.indexOf("api/data") > -1)
                vData = handleNTPayload(vData);


            if(vData && vUrl.indexOf("batch-update/") > -1){
                //batch-update/teamdoc-register/dsTeamdocs/

                var vTmp = vUrl.split("/");
                var vDs = af.DataObject.getByID(vTmp[vTmp.length-2]);
               
                vUrl = '/api/data/'+vDs.getViewName();
                vData = handleNTBatch(vData,vDs);
            }
            if(vData && vUrl.indexOf("bulkinsert/") > -1){
                //batch-update/teamdoc-register/dsTeamdocs/

                var vTmp = vUrl.split("/");
                var vDs = af.DataObject.getByID(vTmp[vTmp.length-1]);
               
                vUrl = '/api/data/'+vDs.getViewName();
                vData = handleNTBulkInsert(vData,vDs);
            }
            if(vData && vUrl.indexOf("bulkdestroy/") > -1){
                //batch-update/teamdoc-register/dsTeamdocs/

                var vTmp = vUrl.split("/");
                var vDs = af.DataObject.getByID(vTmp[vTmp.length-1]);

                console.log(vDs, vData)
               
                vUrl = '/api/data/'+vDs.getViewName();
                vData = handleNTBulkDelete(vData,vDs);
            }
            priv.previousRequest = scope.$.ajax({
                type: "POST",
                async: !priv.options.synchronous,
                url:vUrl.indexOf("api/data") == -1?getRewritedUrl(vUrl): vUrl.startsWith("/") ? vUrl : `/${vUrl}`,
                timeout: priv.options.timeout,
                data: JSON.stringify(vData),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response, status, xhr) {
                    if (response && c.objHasKey(response, "success")) {
                        fireCallback(pCallback, null, response.success);
                    } else if (response && response.error) {
                        if (typeof console !== "undefined" && typeof console.log === "function" && c.objHasKey(response, "stack")) {
                            if (typeof console.groupCollapsed === "function" && typeof console.groupEnd === "function") {
                                console.groupCollapsed(response.error);
                                console.log(response.stack);
                                console.groupEnd();
                            } else {
                                console.log([response.error, response.stack].join("\n"));
                            }
                        }
                        fireCallback(pCallback, response.error, null);
                    } else {
                        fireCallback(pCallback, null, response);
                    }
                },
                error: function (xhr) {
                    var vError = getResponseError.call(this, xhr, function () { request.apply(requestContext, vArgs); });
                    if (typeof vError === "string") {
                        if(fireCallback(pCallback, vError, null) !== false){
                            af.controls.errorDialog(vError, xhr.responseJSON?.errorType);
                        }
                    }
                },
                complete: function () {
                    if (priv.options.abortPrevious && priv.previousRequest !== null) {
                        priv.previousRequest = null;
                    }
                }
            });
            return priv.previousRequest;
        };        

        function apiOverrides(url,pData, pCallback){

            if(url.endsWith('/load-filters')){
                //'filterbuilder/workflow-itemregister/dsItems/load-filters'
                var vUrlParts = url.split("/");
                pData = {
                    HostName:af.article.hostName,
                    ArticleId:vUrlParts[1],
                    DataSource:vUrlParts[2]
                };
                pData['operation'] = "execute"
                pData['procedure'] = "sstp_WebSiteCMS_FilterBuilder_GetData"
                var api = new af.data.Api({
                    url: "/api/data/sstp_WebSiteCMS_FilterBuilder_GetData"
                });
                api(pData, function(pErr,vData){
                    console.log(vData);
                    fireCallback(pCallback, pErr,{
                        fields:vData.Table,
                        filters:vData.Table1,
                    });
                });
              

                /*
                sstp_WebSiteCMS_FilterBuilder_GetData
@HostName = 'Apps',
@ArticleId = 'workflow-itemregister',
@DataSource = 'dsItems'
                */
            }
        }
    }
    function EventedApi(pOptions) {
        var that = this, priv = {
            options: new c.Options({
                defaults: {
                    abortPrevious: false,
                    synchronous: false,
                    timeout: 0
                },
                required: ["url"],
                passed: pOptions
            }),
            eventHandler: new c.EventHandler(that, {
                onProgress: { abortable: true }, // Fires when download or upload is progressing
                onSuccess: { abortable: false }, // Fires when a request succeds
                onError: { abortable: false }, // Fires when a request fails
                onComplete: { abortable: false } // Fires after a request completes
            }),
            hasPlaceholders: false,
            previousRequest: null
        };

        function fireCallback(pCallback, pError, pData) {
            if (typeof pCallback === "function") {
                pCallback.call(that, pError, pData);
            }
        }
        function fireEvent(pEvent, pArgs, pModifiableArgs) {
            return priv.eventHandler.fire(pEvent, pArgs, pModifiableArgs);
        }
        function attachEvent(pEvent, pFunc) {
            return priv.eventHandler.attach(pEvent, pFunc);
        }
        function detachEvent(pEvent, pFunc) {
            return priv.eventHandler.detach(pEvent, pFunc);
        }

        function reportProgress(evt) {
            /*jshint validthis: true */
            var vEventResult = fireEvent("onProgress", evt.lengthComputable ? evt.loaded / evt.total : null);
            if (vEventResult === false) { this.abort(); }
        }
        function request(pData, pCallback) {
            var vArgs = arguments, vUrl = priv.options.url, vData;
            if (priv.options.abortPrevious && priv.previousRequest !== null) {
                priv.previousRequest.abort();
                priv.previousRequest = null;
            }
            if (priv.hasPlaceholders) {
                vData = {};
                c.forEach(pData, function (key, value) {
                    var vPlaceholder = "<%=" + key + "%>";
                    if (~vUrl.indexOf(vPlaceholder)) {
                        vUrl.replace(vPlaceholder, String(value));
                    } else {
                        vData[key] = value;
                    }
                });
            } else {
                vData = pData;
            }
            if(vData.operation === "distinct"){
                vUrl = "/api/data/"+vData.viewName;
            }
            priv.previousRequest = scope.$.ajax({
                type: "POST",
                async: !priv.options.synchronous,
                url: "/" + vUrl,
                timeout: priv.options.timeout,
                data: JSON.stringify(pData),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response, status, xhr) {
                    var vTmp = response;
                    if(vUrl == "/api/dev/dbtools/prepare-sql"){
                        vTmp = {sql:response}
                    }
                    fireEvent("onSuccess", vTmp);
                    fireCallback(pCallback, null, vTmp);
                },
                error: function (xhr) {
                    var vError = getResponseError.call(this, xhr, function () { request.apply(that, vArgs); });
                    if (typeof vError === "string") {
                        fireEvent("onError", vError);
                        fireCallback(pCallback, vError, null);
                    }
                },
                complete: function () {
                    if (priv.options.abortPrevious && priv.previousRequest !== null) {
                        priv.previousRequest = null;
                    }
                    fireEvent("onComplete");
                },
                xhr: function () {
                    try {
                        var xhr = new scope.XMLHttpRequest();
                        if (xhr.upload && typeof xhr.upload.addEventListener === "function") {
                            xhr.upload.addEventListener("progress", reportProgress, false);
                        }
                        if (typeof xhr.addEventListener === "function") {
                            xhr.addEventListener("progress", reportProgress, false);
                        }
                        return xhr;
                    } catch (ex) { }
                }
            });
            return priv.previousRequest;
        }
        function abort() {
            if (priv.previousRequest) {
                priv.previousRequest.abort();
            }
        }
        

        that.attachEvent = attachEvent;
        that.detachEvent = detachEvent;
        that.request = request;
        that.abort = abort;

        if (!/[\w-]+(\/([\w-]+|<%=\w+%>))*/.test(priv.options.url)) {
            throw new Error("Invalid url give to the Api constructor");
        }
        priv.hasPlaceholders = /<%=\w+%>/.test(priv.options.url);
    }


    function handleNTBatch(pData,vDs){
        var vData = {
            ids:pData.Ids,
            viewName:vDs.getViewName(),
            uniqueTable:vDs.uniqueTable()??vDs.getViewName(),
            whereClause:pData.WhereClause,
            key:pData.Key,
            "bulk": true,
         //  masterDetailsString:pData.MasterChildCriteria,
            values:prepareBatchPayload(pData.UpdateColumns,vDs),
            operation:"update"
        };
        if(pData.MasterChildCriteria && Object.keys(pData.MasterChildCriteria).length > 0){
            if(pData.whereClause){
                pData.whereClause = `(${pData.whereClause}) AND (${getMasterDetailsString(pData.MasterChildCriteria)})`
            }else{
                pData.whereClause = getMasterDetailsString(pData.MasterChildCriteria);
            }
        }
        
        return vData;
    }

    function handleNTBulkInsert(pData,vDs){
        var vData = {
            values:prepareBulkInsertData(pData, vDs),
            fields:pData.Fields.map(x=>{return {name:x.name}}),
            viewName:vDs.getViewName(),
            uniqueTable:vDs.uniqueTable()??vDs.getViewName(),
           // whereClause:pData.WhereClause,
           // key:pData.Key,
            "bulk": true,
         //  masterDetailsString:pData.MasterChildCriteria,
          //  values:prepareBatchPayload(pData.UpdateColumns),
            operation:"create"
        };
       /* if(pData.MasterChildCriteria && Object.keys(pData.MasterChildCriteria).length > 0){
            if(pData.whereClause){
                pData.whereClause = `(${pData.whereClause}) AND (${getMasterDetailsString(pData.MasterChildCriteria)})`
            }else{
                pData.whereClause = getMasterDetailsString(pData.MasterChildCriteria);
            }
        }*/
        
        return vData;
    }

    function handleNTBulkDelete(pData,vDs){
        var vData = {
            valuelist:pData.PrimKeys.map(x=>x[0]),
  
            viewName:vDs.getViewName(),
            uniqueTable:vDs.uniqueTable()??vDs.getViewName(),
 
            "bulk": true,
 
            operation:"destroy"
        };

        
        return vData;
    }

    function prepareBulkInsertData(pData, vDs){
        const vReturn = {
            data:[]
        };
        pData.Data.forEach((item)=>{
            const vTmp = {};
            Object.values(item).forEach((val,index)=>{
                vTmp[pData.Fields[index].name] = val;
            })
            Object.keys(pData).forEach(key=>{
                if(['Data','Fields','Json'].indexOf(key) == -1){
                    if(!pData.Fields.find(x=>x.name == key)){
                        pData.Fields.push({
                            name:key
                        });
                    }
                    vTmp[key] = pData[key];
                }
            })

            vReturn.data.push(vTmp);
        })
   

        return vReturn;
        
    }

    function prepareBatchPayload(pData,pDs){
        var vData = {};
        Object.keys(pData).forEach(key=>{
            var vVal = pData[key];
            var vField = pDs.getFields(key);
            if(typeof vVal == "string"){
                vVal= vVal.replaceAll("'","");
            }
            if(vField){
                switch(vField.type){
                    case "number":
                        vVal = parseFloat(vVal);
                        break;
                    case "bit":
                    case "boolean":
                        vVal = parseFloat(vVal);
                        vVal = vVal == 0?false:true;
                        break;
                    default: vVal = vVal.replaceAll("'","");
                }
               vData[key] = vVal; 
            }else{
                
                    vData[key] = vVal;
                
            }
        })

        return vData;
    }

    function handleNTResults(pOptions,pData, pInitOptions){
        if(pOptions.fields && pData.constructor === Array ){
            let vReturn = [];
           // if(!pOptions.excludeFieldNames){
                pData.forEach(row=>{
                    const vRow = [];
                      (pInitOptions.fields?pInitOptions.fields:pOptions.fields).forEach(field=>{

                        if(!field.alias && field.name == "*"){
                            Object.keys(row).forEach(key=>{
                                if(key.indexOf("__Count") > -1){
                                    vRow.push(row[key]);
                                }
                        })
                        }else{
                            vRow.push(row[field.alias??field.name]);
                        }
                        
                        

                    });
                    if(pInitOptions.fields && pOptions.fields.find(x=>x.alias && x.alias.indexOf("__Count") > -1) && pInitOptions.fields.length < pOptions.fields.length){
                        var vCountField = pOptions.fields.find(x=>x.alias && x.alias.indexOf("__Count") > -1);
                        if(vCountField && vCountField.alias && !vRow.hasOwnProperty(vCountField.alias)){
                            vRow.push(row[vCountField.alias]);
                        }
                        
                    }
                    if(row.hasOwnProperty("_recent")){
                        vRow.push(row['_recent']);
                    }
                    if(row.hasOwnProperty("_pinned")){
                        vRow.push(row['_pinned']);
                    }
                    vReturn.push(vRow);

                })
            //}else{
              //  vReturn = pData;
           // }
            if(pOptions.skip !== undefined){
                return {
                    data:vReturn,
                    skip:pOptions["skip"]
                }
            }
            if(pOptions.operation === "update" || pOptions.operation === "create"){
                return vReturn[0];
            }
            return vReturn;
            
        }
        
        return pData;
    }

  

   


    function DataProviderHandlerAPI(pOptions) {
        var that = this, priv = {
            defaults: {
                articleId: w.af.article && w.af.article.id,
                dataSourceId: null,
                timeout: 0,
                fields:null,
                linkFields:null,
                groupBy:false,
                viewName:null,
                uniqueTable:null,
                optimisticLocking:null
            },
            options: {},
            eventHandler: new c.EventHandler(that, {
                onProgress: { abortable: true }, // Fires when download or upload is progressing
                onSuccess: { abortable: false }, // Fires when a request succeds
                onError: { abortable: false }, // Fires when a request fails
                onComplete: { abortable: false} // Fires after a request completes
            }),
            requestTypes: ["create", "retrieve", "update", "destroy", "rowcount", "distinct"],
            previousRetrieveRequest: null
        };

        function fireCallback(pCallback, pError, pData) {
            if (typeof pCallback === "function") {
                pCallback.call(that, pError, pData);
            }
        }
        function fireEvent(pEvent, pArgs, pModifiableArgs) {
            return priv.eventHandler.fire(pEvent, pArgs, pModifiableArgs);
        }
        function attachEvent(pEvent, pFunc) {
            return priv.eventHandler.attach(pEvent, pFunc);
        }
        function detachEvent(pEvent, pFunc) {
            return priv.eventHandler.detach(pEvent, pFunc);
        }

        function reportProgress(evt) {
            var vEventResult = fireEvent("onProgress", evt.lengthComputable ? evt.loaded / evt.total : null);
            if (vEventResult === false) { this.abort(); }
        }


        function request(pType, pData, pCallback, pSynchronous) {
            var vInitOptions = Object.assign({},pData);
            var vRequest, vArgs = arguments;
            if (priv.requestTypes.indexOf(pType) !== -1) {
                if (pType === "retrieve" && priv.previousRetrieveRequest !== null) {
                    priv.previousRetrieveRequest.abort();
                    priv.previousRetrieveRequest = null;
                    if(pData && pData.fieldsr){
                        pData.fields = pData.fields.filter(function(pItem){
                            return pItem.name !== "af_Recent"
                        }) ;
                    }
                }
                
                if (pData !== null) {
                    pData.operation = pType;
                    //pData.excludeFieldNames = true;
                    //pData.resourceName = priv.options.dataSourceId;
                    if(pType !== "distinct"){
                        pData.viewName = pData.viewName || priv.options.viewName;
                        pData.uniqueTable = priv.options.uniqueTable;
                        pData.linkFields = priv.options.linkFields;
                        pData.optimisticLocking = priv.options.optimisticLocking;
                    }
                    if(!pData.fields){
                        pData.fields = priv.options.fields;
                    }
                    pData["articleID"] = af.article.id;
                    if(pType === "distinct"){
                        pData.operation = "retrieve";
                    }
                    
                    vRequest = scope.$.ajax({
                        type: "POST",
                        async: !pSynchronous,
                        url: "/api/data/" + pData.viewName,
                        timeout: priv.options.timeout,
                        data: JSON.stringify(handleNTPayload(pData)),
                        contentType: "application/json; charset=utf-8",
                        dataType: "json",
                        success: function (response, status, xhr) {
                            if (c.objHasKey(response, "success")) {
                                fireEvent("onSuccess", response);
                                fireCallback(pCallback, null, handleNTResults(pData,response.success,vInitOptions));
                            } else if (c.objHasKey(response, "error")) {
                                if (console && typeof console.log === "function" && c.objHasKey(response, "stack")) {
                                    if (typeof console.groupCollapsed === "function" && typeof console.groupEnd === "function") {
                                        console.groupCollapsed(priv.options.dataSourceId + ":", response.error);
                                        console.log(response.stack);
                                        console.groupEnd();
                                    } else {
                                        console.log([priv.options.dataSourceId + ": " + response.error, response.stack].join("\n"));
                                    }
                                }
                                fireEvent("onError", response.error);
                                fireCallback(pCallback, response.error, null);
                            } else {
                                if (console && typeof console.log === "function") {
                                    console.log(priv.options.dataSourceId + ": " + xhr.status + " " + xhr.statusText + ": Unexpected response from server:\n" + xhr.responseText);
                                }
                                fireEvent("onError", "Unspecified error");
                                fireCallback(pCallback, "Unspecified error", null);
                            }
                        },
                        error: function (xhr) {
                            var vError = getResponseError.call(this, xhr, function () { request.apply(that, vArgs); });
                            if (typeof vError === "string") {
                                fireEvent("onError", vError);
                                fireCallback(pCallback, { error: vError, errorType: xhr.responseJSON?.errorType }, null);
                            }
                        },
                        complete: function () {
                            if (priv.previousRetrieveRequest === vRequest) {
                                priv.previousRetrieveRequest = null;
                            }
                            fireEvent("onComplete");
                        },
                        xhr: function() {
                            try {
                                var xhr = new scope.XMLHttpRequest();
                                if (xhr.upload && typeof xhr.upload.addEventListener === "function") {
                                    xhr.upload.addEventListener("progress", reportProgress, false);
                                }
                                if (typeof xhr.addEventListener === "function") {
                                    xhr.addEventListener("progress", reportProgress, false);
                                }
                                return xhr;
                            } catch(ex) {}
                        }
                    });
                    if (pType === "retrieve") {
                        priv.previousRetrieveRequest = vRequest;
                    }
                } else {
                    return false;
                }
            } else {
                throw new TypeError("Invalid request type supplied to the request function");
            }
        }

        function setViewName(pViewName){
            priv.options.viewName = pViewName;
        }

        function setUniqueTable(pUniqueTable){
            priv.options.uniqueTable = pUniqueTable;
        }

        that.setUniqueTable = setUniqueTable;
        that.setViewName = setViewName;
        that.attachEvent = attachEvent;
        that.detachEvent = detachEvent;
        that.request = request;

        // Merge options
        c.mergeOptions(priv.options, priv.defaults, pOptions || {}, 1);
    }
    DataProviderHandlerAPI.prototype = new DataHandler({
        create: function (pData, pCallback, pSynchronous) {
            return this.request("create", pData, pCallback, pSynchronous);
        },
        retrieve: function (pData, pCallback, pSynchronous) {
            return this.request("retrieve", pData, pCallback, pSynchronous);
        },
        update: function (pData, pCallback, pSynchronous) {
            return this.request("update", pData, pCallback, pSynchronous);
        },
        destroy: function (pData, pCallback, pSynchronous) {
            return this.request("destroy", pData, pCallback, pSynchronous);
        },
        rowcount: function (pData, pCallback, pSynchronous) {
            return this.request("rowcount", pData, pCallback, pSynchronous);
        },
        distinctData: function (pData, pCallback) {
            return this.request("retrieve", pData, pCallback, false);
        },
    });


    function exportToExcel(vDO, pFormat, pColumns, pAddDataTypeRow, pOptions) {
        console.log('Hello from NT');
    }
    c.expose('af.dataBinding.Helpers', exportToExcel);

    // af.dataBinding.Helpers.prototype = new af.dataBinding.Helpers({
    //     exportToExcel: function (vDO, pFormat, pColumns, pAddDataTypeRow, pOptions) {
    //         return this.exportToExcel(vDO, pFormat, pColumns, pAddDataTypeRow, pOptions);
    //     }
    // });



    c.expose("af.article", {
        getReports: Api({
            url: "/api/article/<%=ArticleID%>/get-reports",
            parameters: { ArticleID: function () { return af.article.id; } }
        })
    });

    c.expose("af.data", [DataHandler, DataProviderHandler, MemoryStorage, getResponseError, Api, EventedApi, DataProviderHandlerAPI]);
    
    
    function fetch(resource, options){
        
        return w.fetch(getRewritedUrl(resource),options);
    }

    function fixUrlStart(pUrl){
        if(!pUrl.startsWith('/')){
            pUrl = '/' + pUrl;
        }
        return pUrl;
    }

    function getRewritedUrl(pUrl){
         if(pUrl.startsWith("https")) return pUrl;
        pUrl = fixUrlStart(pUrl);
        if(pUrl.startsWith(oldTechSite)){
            return pUrl;
        }
        if(urlRewrites.some( s => pUrl.startsWith(fixUrlStart(s)))){
            pUrl = oldTechSite + pUrl;
        }
        
        return pUrl;
    }

     function windowopen(url,target,feauture){
        window.open(getRewritedUrl(url),target,feauture)
    }
    c.expose("af",windowopen);
    c.expose("af",fetch);
    c.expose("af", getRewritedUrl);

} (this));

(function (af) {
    var c = af.common;
   
    function eventStreamHandler(pOptions){
         var that = this, priv = {
            defaults: {
                articleId: af.article && af.article.id,
                timeout: 0,
            },
            options: {
                url:pOptions.url
            },
            eventHandler: new c.EventHandler(that, {
                onProgress: { abortable: true }, // Fires when download or upload is progressing
                onSuccess: { abortable: false }, // Fires when a request succeds
                onError: { abortable: false }, // Fires when a request fails
                onComplete: { abortable: false}, // Fires after a request completes
                onCancel: { abortable: false} // Fires after a request is cancelled
            }),
            requestTypes: ["create", "retrieve", "update", "destroy"],
            previousRetrieveOptions: null,
            previousCallback: null,
            xhr : new SqlSocket(pOptions.url),
            progress : 0,
            chunk : '',
            data : []
        };

        function fireCallback(pCallback, pError, pData) {
            if (typeof pCallback === "function") {
                pCallback.call(that, pError, pData);
            }
        }
        function fireEvent(pEvent, pArgs, pModifiableArgs) {
            return priv.eventHandler.fire(pEvent, pArgs, pModifiableArgs);
        }
        function attachEvent(pEvent, pFunc) {
            return priv.eventHandler.attach(pEvent, pFunc);
        }
        function detachEvent(pEvent, pFunc) {
            return priv.eventHandler.detach(pEvent, pFunc);
        }

        function retrieve(pOpt, pCallback){
            init();
            var vData = [];

         /*   priv.previousRetrieveOptions = pOpt;
            priv.previousCallback = pCallback;
            
            priv.xhr.open('POST', pOpt.url?pOpt.url:pOptions.url);
            if(pOpt.headers) {
                Object.keys(pOpt.headers).forEach(function (k) {
                    priv.xhr.setRequestHeader(k, pOpt.headers[k]);
                });
            }
            if(pOptions.headers){
                Object.keys(pOptions.headers).forEach(function (k) {
                    priv.xhr.setRequestHeader(k, pOptions.headers[k]);
                });
            }
            priv.xhr.setRequestHeader('Accept', 'text/event-stream');
            if (pOpt.requestData) {
                priv.xhr.send(JSON.stringify(pOpt.requestData));
            } else {
                priv.xhr.send();
            }*/
            priv.xhr.send(pOpt.requestData,function(data){
                if(data && data.hasOwnProperty("FieldCount")){
                    data.fieldCount = data.FieldCount;
                    data.fieldNames = data.FieldNames;
                    data.fieldTypes = data.FieldTypes;
                }
                vData.push(data);
                fireEvent("onProgress", {type:"message",message:data, dataType:"json"},null);
            }).then(function(pArgs){
                 fireEvent("onComplete", {type:"message",message:vData, dataType:"json"},null);
            })
            fireCallback(pCallback, null, null);

        }

        function init(){
            priv.progress = 0;
            priv.chunk = '';
           
          
            //priv.xhr.addEventListener('progress', progress);
        }

        function progress(e){
            if(priv.xhr.status === 401){
                error(e);
                return;
            }
            var vData = priv.xhr.responseText.substring(priv.progress);
            priv.progress += vData.length;
            vData.split(/(\r\n|\r|\n){2}/g).forEach(function (prt,index) {
                if(prt.trim().length === 0 && priv.chunk.length > 0){
                    if (!af.common.isCanToJson(priv.chunk.trim())) {
                        priv.chunk = priv.chunk.replace("event: errordata:","");
                        priv.chunk = priv.chunk.replace("event: error:","");
                        if (!af.common.isCanToJson(priv.chunk.trim())) {
                            fireEvent("onProgress", {type:"message",message:tryParseJsonError(priv.chunk.trim()), dataType:"text"},null);
                        }else{
                            fireEvent("onProgress", {type:"message",message:JSON.parse(priv.chunk.trim()), dataType:"json"},null);
                        }
                      
                    }else{
                        var jsn = JSON.parse(priv.chunk.trim());
                      
                        if (jsn.type === 'eos') {
                            fireEvent("onComplete", {type:"message",message:priv.data, dataType:"json"},null);
                            priv.xhr.removeEventListener('progress', progress);
                            priv.xhr.abort();
                            priv.chunk = '';
                            return;
                        }
                        priv.data.push(jsn);
                        fireEvent("onProgress", {type:"message",message:jsn, dataType:"json"},null);
                        priv.chunk = '';
                    }

                }else{
                    priv.chunk += prt;
                }
            });
        }


  

        function tryParseJsonError(pText){
            pText = pText.replace("event: errordata:","");
            pText = pText.replace("event: error:","");

            console.log(pText.trim());
            if (!af.common.isCanToJson(pText.trim())) {
                return pText.trim()
            }else{
                return JSON.parse(pText.trim());
            }
            
        }
        function cancel(){
            priv.xhr.close();

        }
        function abort(){
            priv.xhr.close();
        }

        function error(e){            
            af.data.getResponseError.call(this, priv.xhr, function () { 
                priv.xhr.removeEventListener('progress', progress);
                priv.xhr.abort();
                retrieve(priv.previousRetrieveOptions, priv.previousCallback);
            });
        }

  

        
        

        that.attachEvent = attachEvent;
        that.detachEvent = detachEvent;
        that.retrieve = retrieve;
        that.cancel = cancel;
        that.abort = abort;
    }

    c.expose("af.dataHandlers",eventStreamHandler);

    class SqlSocket{
        socket;
        url = `wss://${location.host}/api/dev/dbtools/sqlexecute`;
        constructor(pUrl){
            if(pUrl){
                if(pUrl == "api/dbtools/sqlexecute"){
                    pUrl = "/api/dev/dbtools/sqlexecute";
                }
                this.url = `wss://${location.host}/${pUrl}`;
            }
        }
        
        send = async function(pSql,pCallback){
            if(this.url.endsWith("dbtools/sqlexecute")){
                pSql = pSql.script
            }
            await this._openConection();
            return new Promise((resolve,reject)=>{
                this.socket.onclose = function (event) {
                    return reject(event);
                };
                this.socket.onmessage = function (event) {
                    var vTmp = event.data.replace(/{}/g,'null');
                    const vReturn = tryParseJson(vTmp);
                
                    if(vReturn.hasOwnProperty("EOS")){
                        return resolve(true);
                    }
                    if(pCallback) pCallback.call(this,vReturn);
                };
                this.socket.send(JSON.stringify({
                    Script: pSql
                }));
            })
        }
        close = function(){
            if(this.socket && this.socket.readyState === 1){
                this.socket.close(3001,{error:"Execution has been cancelled"});
            }
        }
        _openConection = async function(){
            if(this.socket && this.socket.readyState === 1) return Promise.resolve(true);
            this.socket = new WebSocket(this.url);
            this._initEvents();
            return new Promise(resolve=>{
                this.socket.onopen = ()=>{
                    return resolve(true);
                }
            });
        }
        _initEvents(){
            this.socket.onerror = function(event) { 
                console.log("error",event)
            };
        }

    
    }
    function tryParseJson(pText){
        try{
            return JSON.parse(pText);
        }catch{
            return pText;
        }
    }
         
}(this.af));

class afXMLHttpRequest extends XMLHttpRequest{
    vData = null;
    vFileName = null;
    vDatasource = null;
    
    open(method,url,asyn,username,password){
        url = url.replaceAll("//","/");
        if(url.indexOf("api/get-upload-progress")>-1){
            url = url.replace('api/get-upload-progress','api/file/uploadprogress');

        }else if(url.indexOf("file/upload/")>-1){
            var vTmp = url.split("/");
            var vPrimKey = null;
            var viewName = null;
            
            if(vTmp.length == 6 && vTmp[5]){
                vPrimKey = vTmp[5];
            }
            if(vTmp.length == 6 ){
                var vDs = af.DataObject.getByID(vTmp[4]);
                if(vDs){
                    viewName = vDs.uniqueTable()??vDs.getViewName();
                    this.vDatasource = vDs;
                }

                
            }
            url = url = '/api/file/upload';
            if(viewName){
                url += "/"+viewName;
            }
            if(viewName && vPrimKey){
                url += "/"+vPrimKey;
            }
            if(vTmp.length == 4){
                url = url = '/api/file/chunkupload/'+vTmp[3];
            }

        }else if(url.indexOf("file/upload")>-1){
            url = '/api/file/chunkupload'
        }

       
       
        return super.open(method,af.getRewritedUrl(url),asyn,username,password)
    }
    setFileName = (pFileName) =>{
        this.vFileName = pFileName;
    }
    send = (data)=> {

            if(typeof data == "string"){
                try{
                    var vTmp = JSON.parse(data);
                    if(vTmp.hasOwnProperty("DataSourceID")){
                        vTmp.ViewName = af.DataObject.getByID(vTmp.DataSourceID).getViewName();
                    }
         
                    data = JSON.stringify(vTmp);
                }catch{
                  
                }
            }else if(data && data.constructor == FormData){
                
                
            }else if(data && data.constructor == Blob){
                var vFormData = new FormData();
          
                vFormData.append("File",blobToFile([data])[0],this.vFileName)
                data = vFormData;
                console.log("Blob");
            }
      
            return super.send(data)
        }
}


$.fn.afLoad = function (url, pCallback) {
    if(url.indexOf('api/pims') > -1){
        url = '/legacy'+url;
    }

    return this.load(url,pCallback);
};

function blobToFile(data) {
    let arr = []
    for (const file of data) {
        const mimeType = file.type;
        arr.push(new File([file], mimeType.replace('/', '.'), { type: mimeType, lastModified: Date.now() }));
    }
    return arr;
}


const af_dataObjectExportData = function (pDataObject,pRequestData, pFormat) {
    const name = aT(pRequestData?.fileName ?? pDataObject.getExportedFileName() ?? pDataObject.getViewName().split("_").pop() ?? 'ExportData');

    let requestData = handleNTPayload(pRequestData);
    let exportConfig = new ExportConfig();
    exportConfig.created = pDataObject.export?.getExportDate();
    //exportConfig.contextId = context.id;
    exportConfig.columns = pRequestData.columns;

    exportConfig.filName = name;

    exportConfig.columns.forEach((col) => {
        // Map formats to user session
        if (col.format) {
            col.format = af.userSession.formats[col.format] ?? col.format;
        }

        // Push absent fields to requestData
        if (requestData?.fields?.some(f => col.name.toLowerCase() === f.name.toLowerCase())) return;
        requestData?.fields?.push(col);
    });
    
   

    if(requestData.MasterChildCriteria && Object.keys(requestData.MasterChildCriteria).length > 0){
        requestData.masterDetailsString = getMasterDetailsString(requestData.MasterChildCriteria);
          
    }

    requestData.viewName = pDataObject.getViewName();
    requestData.uniqueTable = pDataObject.uniqueTable();
    requestData.maxRecords = -1;
    requestData.timezoneOffset = -(new Date().getTimezoneOffset());
    requestData.timezoneName =  Intl.DateTimeFormat().resolvedOptions().timeZone;
    requestData.fields = handleNTPayload({fields:pDataObject.getExtendedFields()}).fields;
    requestData.columns = undefined;

  

    return af_exportData("/api/data/export/" + name + "." + pFormat, {
        requestData: requestData,
        exportConfig: exportConfig
    });
}

const af_exportData = async function (pUrl, pConfig) {
    if (pConfig['exportConfig'].localizeDateTime === true) {
        pConfig['requestData'].localizeDateTime = true;
        pConfig['requestData'].timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    const body = {
        requestData: pConfig['requestData'],
        exportConfig: pConfig['exportConfig']
    };

    const response = await fetch(pUrl, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers({
            'Content-Type': 'application/json'
        })
    });

    if (response.status === 200) {
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = pUrl.split('/').pop();
        link.click();

        URL.revokeObjectURL(blobUrl);
    } else {
        let message = ''; 
        try {
            const json = await response.json();
            message = json.error ?? at('Data export failed.');
        } catch (ex) {
            message = at('Data export failed.');
        }
        throw new Error(message);
    }
}

class ExportConfig {

    sheetName = 'Data Export';
    includeHeader = true;
    includeTitleRow = true;
    autoFitColumns = true;
    includeDataTypes = false;
    includeFilter = false;
    exportAsImportTemplate = false;
    includeExportedDateTime = true;
    includePivotTable = false;
    localizeDateTime = false;
    host = window.location.origin;
    columns = [];
}

 function getMasterDetailsString(pObject){
    if(!pObject) return null;
    const vReturn = [];
    Object.keys(pObject).forEach(field=>{
        if(pObject[field] == null){
            vReturn.push(`${field} IS NULL`)
        }else{
                console.log(typeof pObject[field])
            if(pObject[field].constructor == Date){
        
                    vReturn.push(`${field} = '${pObject[field].toJSON()}'`)
            }else if(typeof pObject[field] === 'string'){
                    vReturn.push(`${field} = '${pObject[field].replace(/'/g,"''")}'`)
            }else{
                    vReturn.push(`${field} = '${pObject[field]}'`)
            }
            
        }
    })

    return vReturn.join(" AND ")

}

 function checkFirstLetterCapital(_string)
{
    return _string[0] === _string[0].toUpperCase();
}
    function handleNTPayload(pOptions){
        if(pOptions.fields){
            pOptions.fields = pOptions.fields.map(x=>{ return typeof x == "string"?{name:x}:{ name:x.name,
                                                                type:x.type,
                                                                aggregate:x.aggregate?x.aggregate:undefined,
                                                                alias:x.alias
                                                                }});
        }
        if(pOptions.fields && pOptions.sortOrder){
            pOptions.sortOrder.forEach((sort,index)=>{
                const field = Object.keys(sort)[0];
                if(field){
                    const vField = pOptions.fields.find(x=>x.name == field);
                    let vDirection = sort[field];
                    if(vDirection.indexOf("null") > -1){
                    pOptions.distinctRows = false;
                    }
                    if(vField){
                        vField.sortDirection = vDirection;
                        vField.sortOrder = index+1;
                       // delete vField.aggregate;
                    }else{
                        if(field) pOptions.fields.push({
                            name:field,
                            sortDirection:vDirection,
                            sortOrder:index+1
                        })
                    }
                }
            })
        
        }
        if(pOptions.fields && pOptions.groupBy){
            pOptions.groupBy.forEach((group,index)=>{
                const vField = pOptions.fields.find(x=>x.name == group);
                if(vField){
                    vField.groupByOrder = index+1;
                }else{
                     pOptions.fields.push({
                        name:group,
                        groupByOrder:index+1
                    })
                }
            })
        }

        if(pOptions.fields && pOptions.fields.find(x=>x.name?.indexOf("___Count") > -1)){
            //distinct operation
            var vRemoveDistField = true;
            const vCountFieldIndex =  pOptions.fields.findIndex(x=>x.name.indexOf("___Count") > -1);
            const vRealField = pOptions.fields[vCountFieldIndex].name.replace("___Count","");
            let vDistField =  pOptions.fields.find(x=>x.name === "*");
            if(vDistField){
                vDistField.alias = pOptions.fields[vCountFieldIndex].name;
                if(pOptions.fields[vCountFieldIndex].groupByOrder)
                    vDistField.groupByOrder = pOptions.fields[vCountFieldIndex].groupByOrder;
                if(pOptions.fields[vCountFieldIndex].aggregate)
                    vDistField.groupByAggregate = pOptions.fields[vCountFieldIndex].aggregate;
                
                if(pOptions.fields[vCountFieldIndex].sortOrder)
                    vDistField.sortOrder = pOptions.fields[vCountFieldIndex].sortOrder;
                
                if(pOptions.fields[vCountFieldIndex].sortDirection)
                    vDistField.sortDirection = pOptions.fields[vCountFieldIndex].sortDirection;

            
                vDistField.groupByAggregate = "COUNT";
        
        
                pOptions.fields.splice(vCountFieldIndex,1);
              
            }else{
                vDistField =  pOptions.fields.find(x=>x.name === vRealField+"___Count");
                 vDistField.alias = pOptions.fields[vCountFieldIndex].name;
                 vDistField.name = vRealField;

                vDistField.aggregate = "COUNT";
               // vDistField.groupByOrder = 1;

            }
           /*  if(!vDistField){
                 vDistField =  pOptions.fields.find(x=>x.name === vRealField+"___Count");
                 vDistField.alias = pOptions.fields[vCountFieldIndex].name;
                 vDistField.name = "*";

                
            } */

            
        }

        if(pOptions.fields){
            if(pOptions.fields.find(x=>x.name==="af_Recent")){
                pOptions.loadRecents = true;
            }
            pOptions.fields.forEach(field=>{
                if(field["aliasName"]){
                    field['alias'] = field["aliasName"];
                }
                if(field["aggregate"]){
                 //   field['groupByAggregate'] = field["aggregate"];
                }
                if(field['groupByAggregate'] === "COUNT_D") field['groupByAggregate'] = "COUNT_DISTINCT";
                if(field['aggregate'] === "COUNT_D") field['aggregate'] = "COUNT_DISTINCT";
               // delete field["aggregate"];
            })
            pOptions.fields = pOptions.fields.filter(x=>x.name && !x.name.startsWith("af_Recent"));
            pOptions.fields = pOptions.fields.filter(x=>x.name && !x.name.startsWith("af_Selected"));
            pOptions.fields = pOptions.fields.filter(x=>x.name && !x.name.startsWith("af_Pinned"));
        }
        Object.keys(pOptions).forEach(key=>{
            if(checkFirstLetterCapital(key)){
                if(!pOptions.hasOwnProperty("values")){
                    pOptions.values = {}
                }
                pOptions.values[key] = pOptions[key];
                delete pOptions[key];
            }
        })

        if(!pOptions.groupByFilter){
            pOptions.groupByFilter = null;
        }
       /* if(pOptions.whereObject){
            var vWhereString = af.Filter.toSQL(pOptions.whereObject);
            pOptions.whereClause = combineClauses(pOptions.whereClause,vWhereString);
        }*/
        if(pOptions.masterChildCriteria)
            pOptions.masterDetailString = getMasterDetailsString(pOptions.masterChildCriteria);
        if(pOptions.operation && pOptions.operation == "recordinfo"){
            pOptions["values"] = {

                ID:pOptions.id,
                PrimKey:pOptions.primKey
            }
        }
        if(pOptions.operation && ['rowcount', 'retrieve', 'distinct'].includes(pOptions.operation)) {
            // Make sure timezone is always provided in retrieve-esque requests
            pOptions.timezoneOffset = pOptions?.timezoneOffset ?? -(new Date().getTimezoneOffset());
            pOptions.timezoneName = pOptions?.timezoneName ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return pOptions;
    }

    function combineClauses(pClause1, pClause2){
        if(pClause1 && pClause2){
            return `(${pClause1}) AND (${pClause2})`;
        }

        if(pClause1){
            return pClause1;
        }
        if(pClause2){
            return pClause2;
        }
    }